<template>
    <div>
        <!-- Latest compiled and minified CSS -->
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
              integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossorigin="anonymous">
        <section class="page_404">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 ">
                        <div class="col-sm-12 text-center">
                            <div><img src="/img/iRenderLogo.svg" style="width: 300px; max-width: 100%;" /></div>
                            <div class="four_zero_four_bg">
                                <h1 class="text-center ">
                                    <strong>ACCESS NOT GRANTED</strong>
                                </h1>

                            </div>

                            <div class="contant_box_404">
                                <h3 class="h2 detail-content d-inline" style="">
                                    Sorry, it's not allowed to go beyond this point!
                                </h3>

                            </div>
                            <div class="mt-5">
                                <a href="/dashboard" class="btn btn-lg rounded-circle bg-gradient-primary">Go to Dashboard</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<style>

    /*======================
        404 page
    =======================*/

    .page_404 {
        padding: 40px 0;
        background: #fff;
        font-family: "Arial", serif;
        height: 100dvh;
        display: flex;
        align-items: center;
    }

        .page_404 img {
            width: 100%;
        }

    .four_zero_four_bg {
        background-image: url(/img/dribbble_1.gif);
        height: 400px;
        background-position: center;
        background-repeat: no-repeat;
    }

        .four_zero_four_bg h1 {
            font-size: 24px;
        }

        .four_zero_four_bg h3 {
            font-size: 80px;
        }

    .link_404 {
        color: #fff !important;
        padding: 10px 20px;
        background: #39ac31;
        margin: 20px 0;
        display: inline-block;
    }

    .contant_box_404 {
        margin-top: -50px;
    }

    .detail-content {
        font-size: 16px;
        line-height: 25px;
    }

    /* Small devices (tablets, 768px and up) */
    @media screen and (min-width: 768px) {
    }

    /* Medium devices (desktops, 992px and up) */
    @media screen and (min-width: 992px) {
        .detail-content {
            font-size: 22px;
            line-height: 30px;
        }

        .four_zero_four_bg h1 {
            font-size: 30px;
        }
    }

    /* Large devices (large desktops, 1200px and up) */
    @media screen and (min-width: 1200px) {
    }
</style>

<script>
    export default {

    }
</script>